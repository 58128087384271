<template>
    <main class="flex flex-col items-center justify-start pt-24 relative h-fit">
        <HeroSection />
        <ValueSection />
        <MetricSection />
        <HowSection />
        <ToolSection />
        <TestimonySection />
        <div class="absolute rounded-full border-2 border-[#FF725E] w-[60rem] h-[60rem] left-0 bottom-[70rem] -translate-x-3/4 translate-y-1/2 bg-transparent -z-50 hidden lg:block"></div>
    </main>
</template>

<script>
import HeroSection from "../components/LandingPage/HeroSection.vue";
import ValueSection from "../components/LandingPage/ValueSection.vue";
import MetricSection from "../components/LandingPage/MetricSection.vue";
import HowSection from "../components/LandingPage/HowSection.vue";
import ToolSection from "../components/LandingPage/ToolSection.vue";
import TestimonySection from "@/components/LandingPage/TestimonySection.vue";
export default {
    name: "LandingPage",
    components: { HeroSection, ValueSection, MetricSection, HowSection, ToolSection, TestimonySection },
};
</script>

<style lang="scss" scoped></style>
