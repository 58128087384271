<template>
    <div class="invoice-container">
        <!-- <div class="navigation-tabs">
            <div class="nav-links">
                <router-link to="/payement" @click="selectTab(0)" :class="{ active: selected[0] }">
                    <font-awesome-icon :icon="['far', 'credit-card']" class="nav-icon" />
                    <span>{{ $t("Pricing Plans") }}</span>
                </router-link>
                <router-link id="active" to="/invoice" @click="selectTab(1)" :class="{ active: selected[1] }">
                    <font-awesome-icon :icon="['fas', 'chart-bar']" class="nav-icon" />
                    <span>{{ $t("Invoice") }}</span>
                </router-link>
            </div>
        </div> -->
        <div class="invoice-actions">
            <span>{{ $t("Invoice ID") }}: {{ invoice.order }}</span>
            <button @click="downloadPDF">
                <font-awesome-icon :icon="['fas', 'file-arrow-down']" />
                {{ $t("Download as PDF") }}
            </button>
            <button @click="printInvoice"><font-awesome-icon :icon="['fas', 'print']" /> {{ $t("Print") }}</button>
            <button><font-awesome-icon :icon="['fas', 'dollar-sign']" />{{ $t("Recieve Payements") }}</button>
        </div>
        <div class="invoice-content">
            <div class="invoice-header">
                <div>
                    <img loading="lazy" decoding="async" src="../assets/Images/go_logo-copy.svg" alt="" width="150" height="100" />
                </div>
                <span>{{ $t("Invoice") }}</span>
                <span>GO PLATFORM</span>
                <span>24 Messaoudi Ouchen, Blida, Algeria, 09000</span>
            </div>
            <div class="invoice-infos">
                <div class="left">
                    <span>{{ $t("Invoice to") }}</span>
                    <span>{{ invoice.to }}</span>
                    <span>{{ invoice.adress }}</span>
                    <span>{{ invoice.email }}</span>
                    <span>{{ invoice.phone }}</span>
                </div>
                <div class="right">
                    <span
                        >{{ $t("Invoice No") }}: <span>{{ invoice.num }}</span></span
                    >
                    <span
                        >{{ $t("Order Num") }}: <span>{{ invoice.order }}</span></span
                    >
                    <span
                        >{{ $t("Invoice Date") }}: <span>{{ invoice.date }}</span></span
                    >
                    <span
                        >{{ $t("Payement Due") }}: <span>{{ invoice.due }}</span></span
                    >
                    <span
                        >{{ $t("Amount Due") }}: <span>${{ (total_amount * (1 + 0.08)).toFixed(2) }}</span></span
                    >
                </div>
            </div>
            <div class="invoice-table">
                <table>
                    <tr>
                        <th>Test</th>
                        <th>{{ $t("Quantity") }}</th>
                        <th>{{ $t("Rate") }}</th>
                        <th>{{ $t("Amount") }}</th>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index">
                        <td>{{ item.assessement }}</td>
                        <td>{{ item.Qte }}</td>
                        <td>${{ item.rate }}</td>
                        <td>${{ item.Qte * item.rate }}</td>
                    </tr>
                </table>
                <div class="total">
                    <span
                        >{{ $t("Sub Total") }}: <span>$ {{ total_amount }}</span></span
                    >
                    <span>
                        Tax 8%:
                        <span>$ {{ ((total_amount * 8) / 100).toFixed(2) }}</span></span
                    >
                    <span
                        >Total: <span>$ {{ (total_amount * (1 + 0.08)).toFixed(2) }}</span></span
                    >
                    <span
                        >{{ $t("Amount Due") }}: <span>$ {{ parseFloat((total_amount * (1 + 0.08)).toFixed(2)) }}</span></span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { style } from "@/store/InvoiceStyle";
export default {
    name: "InvoiceView",
    data() {
        return {
            invoice: {
                ID: "00000",
                to: "john smith",
                adress: "1954 Bloor Street West Torronto ON, M6P 3K9 Canada",
                email: "example@email.com",
                phone: "+197348356",
                num: 14,
                order: "DE2180",
                date: new Date("2022/02/25").toLocaleDateString("en-GB"),
                due: "upon reciept",
            },
            items: [
                { assessement: "Javascript developer", Qte: 15, rate: 100 },
                { assessement: "graphic designer", Qte: 20, rate: 120 },
                { assessement: "Business analyst", Qte: 27, rate: 300 },
                { assessement: "software developer", Qte: 33, rate: 220 },
            ],
            selected: [false, true],
            current: 1,
        };
    },
    computed: {
        total_amount() {
            return this.items.reduce((total, item) => total + item.Qte * item.rate, 0);
        },
    },
    methods: {
        downloadPDF() {
            const element = document.querySelector(".invoice-content"); // Select the element to be converted to PDF

            // Create a new jsPDF instance
            const doc = new jsPDF({
                unit: "in",
                format: "letter",
                orientation: "portrait",
            });

            // Set margins (optional)
            const margins = {
                top: 0.5,
                bottom: 0.5,
                left: 0.5,
                right: 0.5,
            };

            // Convert the element to canvas using html2canvas and add it to the PDF
            html2canvas(
                element, //{
                // scale: 1 // Set the scale of the HTML to Canvas conversion (optional)
                /*}*/
            ).then((canvas) => {
                const imageData = canvas.toDataURL("image/png");

                // Add the image data to the PDF
                doc.addImage(
                    imageData,
                    "PNG",
                    margins.left,
                    margins.top,
                    doc.internal.pageSize.getWidth() - (margins.left + margins.right),
                    doc.internal.pageSize.getHeight() - (margins.top + margins.bottom),
                );

                // Save the PDF
                doc.save("invoice.pdf");
            });
        },
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        printInvoice() {
            const element = document.querySelector(".invoice-content"); // Select the element to be printed
            const printWindow = window.open("", "_blank"); // Open a new blank window for printing
            printWindow.document.write(
                `<html>
                    <head>
                        <title>Print</title>
                        <style>${style}</style>
                    </head>
                    <body></body>
                </html>`,
            ); // Create a new HTML document
            printWindow.document.body.appendChild(element.cloneNode(true)); // Append the cloned element to the new document's body
            printWindow.document.close(); // Close the document writing
            printWindow.print(); // Trigger the print dialog for the new window
            printWindow.onafterprint = function () {
                printWindow.close(); // Close the new window after printing is done
            };
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
button:hover {
    opacity: 0.85;
}

.invoice-container {
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 1rem;

    .invoice-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        color: #344050;
        background: #fff;
        box-shadow:
            0px 7px 14px rgba(65, 69, 88, 0.1),
            0px 3px 6px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        padding: 1rem;

        > :first-child {
            flex: 0 0 60%;
            font-size: 19px;
            line-height: 28px;
        }

        > button {
            background: #fff;
            border: 1px solid #ffffff;
            box-shadow:
                0px 1px 2px rgba(0, 0, 0, 0.08),
                0px 1px 1.5px rgba(0, 0, 0, 0.07),
                0px 2px 5px rgba(43, 45, 80, 0.08),
                0px 0px 0px 1px rgba(43, 45, 80, 0.1);
            border-radius: 20px;
            padding: 8px;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: #5d6e82;
        }
    }

    .invoice-content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        background: #fff;
        box-shadow:
            0px 7px 14px rgba(65, 69, 88, 0.1),
            0px 3px 6px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        padding: 1rem;

        .invoice-header {
            display: grid;
            grid-template-columns: 4fr 1fr;
            position: relative;
            padding-bottom: 1rem;

            > :first-child {
                grid-column: 1;
                grid-row: 1 / span 3;
            }

            > :nth-child(2) {
                grid-column: 2;
                grid-row: 1;
                font-weight: 500;
                font-size: 33px;
                line-height: 50px;
            }

            > :nth-child(3) {
                grid-column: 2;
                grid-row: 2;
                font-weight: 500;
                font-size: 19px;
                line-height: 28px;
            }

            > :nth-child(4) {
                grid-column: 2;
                grid-row: 3;
                font-weight: 600;
                font-size: 13px;
                line-height: 17px;
                color: #5d6e82;
            }

            &::after {
                content: "";
                width: 100%;
                background: #edf2f8;
                height: 1px;
                position: absolute;
                bottom: 0;
            }
        }

        .invoice-infos {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > * {
                display: flex;
                flex-direction: column;
                width: 20%;
            }

            .left {
                > :first-child {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #9da8ba;
                }

                > :nth-child(2) {
                    font-weight: 500;
                    font-size: 19px;
                    line-height: 28px;
                }

                > :nth-child(3) {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    color: #5d6e82;
                }

                > :nth-child(4),
                > :nth-child(5) {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    color: #2196f3;
                }
            }

            .right {
                font-weight: 600;
                font-size: 13px;
                line-height: 17px;
                color: #5d6e82;
                display: flex;
                flex-direction: column;
                gap: 0.25rem;

                > * {
                    display: flex;
                    justify-content: space-between;
                    padding: 2px;

                    span {
                        flex: 0 0 50%;
                        font-weight: 400;
                        text-align: left;
                    }
                }

                > :last-child {
                    color: #00864e;

                    span {
                        font-weight: 700;
                    }

                    background: #e0faef;
                }
            }
        }

        .invoice-table {
            display: flex;
            flex-direction: column;
            align-items: end;

            table {
                width: 100%;

                > :nth-child(odd) {
                    background: #f9fafd;
                }

                tr {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    padding: 1rem;
                    color: #344050;

                    td {
                        padding: 0.5rem 1rem;
                        text-align: end;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 17px;
                        color: #5d6e82;
                    }

                    > :first-child {
                        text-align: start;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                > :first-child {
                    background: #2196f3;
                    color: #fff;

                    th {
                        text-align: center;
                        padding: 0.5rem;
                    }
                }
            }

            .total {
                width: 20%;
                display: flex;
                gap: 0.5rem;
                flex-direction: column;
                font-weight: 600;
                font-size: 13px;
                line-height: 17px;

                > * {
                    display: flex;
                    justify-content: space-between;
                    padding: 0.25rem;

                    span {
                        color: #5d6e82;
                        text-align: end;
                    }
                }

                > :nth-child(3) {
                    position: relative;

                    &::after,
                    &::before {
                        content: "";
                        display: flex;
                        width: 100%;
                        height: 1px;
                        background: #d8e2ee;
                        position: absolute;
                    }

                    &::after {
                        bottom: 0;
                    }

                    &::before {
                        top: 0;
                    }
                }

                :last-child {
                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.navigation-tabs {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;

    border-bottom: 2px solid #edeff2;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}
</style>
