<template>
    <div class="landingPageContainer relative pb-20">
        <ToastNotification :isVisible="numberCopied" message="Phone number copied!" bgColor="#fff" textColor="#2196f3" />
        <div class="header"></div>

        <div class="landing_text_bg">
            <div class="landing_text_wrapper blur_bg" ref="hero">
                <span class="absolute -z-10 top-28 left-20 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag">
                    Psychometrics</span
                >
                <span class="absolute -z-10 top-80 left-32 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag">
                    Logical reasoning</span
                >
                <span
                    class="absolute -z-10 top-54 right-28 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Google verbal ability</span
                >
                <span
                    class="absolute -z-10 bottom-64 right-20 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Soft skills</span
                >
                <span class="absolute -z-10 top-48 left-52 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag">
                    Hard skills</span
                >
                <span
                    class="absolute -z-10 bottom-72 left-64 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Deloitte aptitude test</span
                >
                <span
                    class="absolute -z-10 top-80 right-72 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Time management</span
                >
                <span
                    class="absolute -z-10 top-64 right-20 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Capgemini aptitude</span
                >
                <span
                    class="absolute -z-10 top-40 right-60 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Deloitte placement test</span
                >
                <span
                    class="absolute -z-10 top-24 left-1/3 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Deloitte aptitude test</span
                >
                <span
                    class="absolute -z-10 bottom-72 right-40 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Team work</span
                >
                <span
                    class="absolute -z-10 top-12 left-1/2 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Business English</span
                >
                <span
                    class="absolute -z-10 top-96 right-96 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Quantitive aptitude</span
                >
                <span
                    class="absolute -z-10 top-54 right-2/3 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Google verbal ability</span
                >
                <span
                    class="absolute -z-10 bottom-52 left-40 text-neutral-800 w-fit h-fit p-4 shadow-[0_0px_14px_0_rgba(0,0,0,15%)] font-thin text-base bg-white border border-gray-200 rounded-full tag"
                >
                    Google logical reasoning</span
                >
                <div class="landing_text">
                    <span v-html="$t('hero-text')"> </span>

                    <!-- <span> REIMAGINING RECRUITEMENT </span> -->
                    <!-- <span v-html="$t('hero-subtext')"> </span> -->
                    <div class="btn_wrapper">
                        <button class="w-fit">
                            <router-link class="a" to="/register">
                                {{ $t("Start for free") }}
                                <img loading="lazy" decoding="async" src="../assets/Images/arrow_to_right.svg" alt="Find Candidates" height="20" width="10"
                            /></router-link>
                        </button>
                        <!-- <button class="w-fit">
                            <router-link class="a cantact" to="/">
                                {{ $t("Contact us") }}
                                <img loading="lazy"  decoding="async" src="../assets/Images/arrow_to_right_white.svg" alt="Find Candidates" height="20" width="10"
                            /></router-link>
                        </button> -->
                    </div>
                    <!-- <span class="creditCard">No credit card required</span> -->
                </div>
            </div>
        </div>
        <div class="content_wrapper">
            <div class="partners">
                <span> {{ $t("Inspired By") }} </span>
                <div>
                    <Vue3Marquee :cloned="true" :pauseOnHover="false" :gradient="true" :gradient-color="'#fff'" gradientLength="30%">
                        <img loading="lazy" decoding="async" class="mx-auto" src="../assets/landing-page-logos/aws.svg" alt="Partner" />
                        <img loading="lazy" decoding="async" src="../assets/landing-page-logos/ey.svg" alt="Partner" />
                        <img loading="lazy" decoding="async" class="mx-auto" src="../assets/landing-page-logos/pwc.svg" alt="Partner" />
                        <img loading="lazy" decoding="async" class="mx-auto" src="../assets/landing-page-logos/kpmg.svg" alt="Partner" />
                        <img loading="lazy" decoding="async" class="mx-auto" src="../assets/landing-page-logos/deloitte.svg" alt="Partner" />
                    </Vue3Marquee>
                </div>
            </div>
            <div class="text_video_wrapper">
                <div class="safari-top-bar bg-gray-100 h-16 flex items-center justify-between px-4">
                    <!-- Window controls section -->
                    <div class="flex items-center space-x-2">
                        <!-- Red dot for close -->
                        <div class="window-dot red"></div>

                        <!-- Yellow dot for minimize -->
                        <div class="window-dot yellow"></div>

                        <!-- Green dot for maximize -->
                        <div class="window-dot green"></div>
                    </div>

                    <!-- Search bar -->
                    <div class="flex items-center justify-center flex-grow">
                        <input
                            type="text"
                            class="bg-transparent w-full text-center border-b border-gray-300 px-2 py-1 focus:outline-none focus:border-blue-500 text-gray-700"
                            placeholder="Search"
                            v-model="searchQuery"
                        />
                    </div>

                    <!-- Navigation section -->
                    <div class="flex items-center space-x-4">
                        <button @click="goBack" class="text-gray-600">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <button @click="goForward" class="text-gray-600">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <video src="../assets/landing-page-logos/presentationVideo.mp4" autoplay muted loop height="620" width="1440">your browser doesn't support video tag</video>
            </div>

            <div class="proccess_steps_wrapper">
                <span>{{ $t("All the tools that you need") }} </span>
                <span></span>
                <div class="process_steps panels px-32 py-20 bg-white lg:h-[100vh]" ref="accuracy">
                    <div class="text">
                        <span class="accuracy_text">{{ $t("Accuracy") }}</span>
                        <h1 class="accuracy_text">{{ $t("Precise & Valid Skills Data") }}</h1>
                        <p class="accuracy_text">{{ $t("accuracyText") }}</p>
                    </div>
                    <div class="relative h-full">
                        <!-- <img loading="lazy"  decoding="async" src="../assets/landing-page-logos/Accuracy.svg" alt="" width="1440" /> -->
                        <img loading="lazy" decoding="async" src="../assets/Images/widget.svg" alt="widget" class="accuracy_image hover:scale-110 absolute top-4 right-6 p-0 rounded-md w-48 h-24" />
                        <img
                            loading="lazy"
                            src="../assets/Images/widget2.svg"
                            alt="widget2"
                            class="accuracy_image hover:scale-110 absolute bottom-1 left-12 shadow-[0_0_10px_1px_rgba(0,0,0,25%)] rounded-md w-48 h-24"
                        />
                        <img
                            loading="lazy"
                            src="../assets/Images/webchart.svg"
                            alt="widget"
                            class="accuracy_image hover:scale-110 absolute bottom-5 right-2 w-72 h-72 shadow-[0_0_10px_1px_rgba(0,0,0,25%)] rounded-md"
                        />
                        <img
                            loading="lazy"
                            src="../assets/Images/profileCandidate.svg"
                            alt="widget2"
                            class="accuracy_image hover:scale-110 absolute top-2 left-3 shadow-[0_0_10px_1px_rgba(0,0,0,25%)] rounded-md w-64 h-64"
                        />
                        <img
                            loading="lazy"
                            src="../assets/Images/accuracy_candidates.svg"
                            alt="widget2"
                            class="accuracy_image hover:scale-110 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] object-cover shadow-[0_0_10px_1px_rgba(0,0,0,25%)] rounded-md w-72 h-64"
                        />
                    </div>
                </div>
                <div class="process_steps panels px-32 py-20 bg-white lg:h-[100vh]" ref="time">
                    <div class="text">
                        <span class="cost_text">{{ $t("Time Effectiveness") }}</span>
                        <h1 class="cost_text">{{ $t("Accelerated Hiring Velocity") }}</h1>
                        <p class="cost_text">
                            {{ $t("timeEffectivenessText") }}
                        </p>
                    </div>
                    <div class="relative h-full w-full">
                        <!-- <img loading="lazy"  decoding="async" src="../assets/landing-page-logos/Time Efficient.svg" alt="" width="1440" /> -->
                        <!-- <img loading="lazy"  decoding="async" src="../assets/Images/bg_cost.png" alt="widget"  class="cost_image_bg absolute top-0 right-0 p-0 rounded-md w-full h-full z-0" /> -->
                        <img loading="lazy" decoding="async" src="../assets/Images/man-cost.svg" alt="widget2" class="cost_image absolute -right-28 rounded-md w-full h-full" />
                        <img loading="lazy" decoding="async" src="../assets/Images/time_chart.svg" alt="widget" class="cost_image hover:scale-110 absolute bottom-5 left-2 w-80 h-52 rounded-md" />
                        <img loading="lazy" decoding="async" src="../assets/Images/time_chart2.svg" alt="widget2" class="cost_image hover:scale-110 absolute top-2 left-5 rounded-md w-56 h-64" />
                    </div>
                </div>
                <div class="process_steps panels px-32 py-20 bg-white lg:h-[100vh]" ref="cost">
                    <div class="text">
                        <span class="cost2_text">{{ $t("Cost Efficiency") }}</span>
                        <h1 class="cost2_text">{{ $t("Stop the Bleed from Unfilled Positions") }}</h1>
                        <p class="cost2_text">{{ $t("costEficiencyText") }}</p>
                    </div>
                    <!-- <div>
                        <img loading="lazy"  decoding="async" src="../assets/landing-page-logos/Cost.png" alt="" width="1440" />
                    </div> -->
                    <div class="relative h-full w-full">
                        <!-- <img loading="lazy"  decoding="async" src="../assets/landing-page-logos/Time Efficient.svg" alt="" width="1440" /> -->
                        <!-- <img loading="lazy"  decoding="async" src="../assets/Images/cost_bg2.png" alt="widget"  class="cost2_image_bg absolute top-0 right-0 p-0 rounded-md w-full h-full z-0" /> -->
                        <img loading="lazy" decoding="async" src="../assets/Images/woman_cost.svg" alt="widget2" class="cost2_image absolute top-0 -right-20 rounded-md w-full h-full" />
                        <img loading="lazy" decoding="async" src="../assets/Images/cost_widget.svg" alt="widget" class="cost2_image hover:scale-110 absolute top-2 right-40 w-48 h-32 rounded-md" />
                        <img loading="lazy" decoding="async" src="../assets/Images/cost_chart.svg" alt="widget2" class="cost2_image hover:scale-110 absolute top-16 left-10 rounded-md w-72 h-56" />
                        <div
                            class="cost2_image lg:w-3/5 text-neutral-700 h-fit bg-white shadow-[0_0_4px_1px_rgba(0,0,0,5%)] rounded-md absolute left-20 bottom-2 gap-3 p-6 flex flex-col justify-center items-center"
                        >
                            <h1 class="font-semibold text-3xl text-left w-full">Cost Per Talent</h1>
                            <div class="flex flex-row items-center justify-between w-full gap-3">
                                <div class="w-2/5 flex flex-col gap-2">
                                    <h2 class="w-full text-left font-extrabold text-4xl">6.7$</h2>
                                    <span class="w-full text-left text-base font-normal text-gray-500"> CURRENT TALENT </span>
                                </div>
                                <div class="w-2/5 flex flex-col gap-2">
                                    <h2 class="w-full text-left font-extrabold text-4xl">56.2$</h2>
                                    <span class="w-full text-left text-base font-normal text-gray-500"> AVERAGE </span>
                                </div>
                            </div>
                            <a class="font-bold text-xl text-left w-full text-NeonBlue" href="/#">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref="slack"
                class="w-11/12 relative rounded-3xl my-20 mx-auto flex flex-col gap-4 justify-between text-white items-center shadow-[0_0_10px_1px_rgba(0,0,0,25%)] overflow-hidden py-24 px-14"
            >
                <img loading="lazy" decoding="async" src="../assets/Images/cost_bg2.png" alt="widget" class="absolute top-0 right-0 p-0 rounded-md w-full h-full -z-10 object-fill" />
                <h2 class="w-full text-center text-lg font-semibold">{{ $t("COMMUNITY") }}</h2>
                <h1 class="w-full text-center text-4xl font-bold text-shadow" v-html="$t('help-text')"></h1>
                <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-6 mt-6">
                    <a
                        href="https://join.slack.com/t/gocommunity/shared_invite/zt-28edaert0-Qkgc~~pSR1LrQQMigD28Sg"
                        target="_blank"
                        class="w-full lg:w-64 h-fit px-4 py-3 border-[3px] rounded-full font-bold text-xl border-white text-white hover:bg-white hover:text-NeonBlue hover:border-NeonBlue hover:scale-110 transition-all duration-300"
                    >
                        Join Slack
                    </a>
                    <button
                        class="w-full lg:w-64 h-fit px-4 py-3 border-[3px] rounded-full font-bold text-xl border-white text-NeonBlue bg-white hover:border-NeonBlue hover:scale-110 transition-all duration-300"
                    >
                        Sign up for Free
                    </button>
                </div>
            </div>
            <!-- <div class="bottom_text">
                <img loading="lazy"  decoding="async" src="../assets/landing-page-logos/bottom_text.svg" />
                <span>{{ $t("COMMUNITY") }}</span>
                <h1 v-html="$t('help-text')"></h1>
                <p>
                    {{ $t("community-text") }}
                </p>
                <div class="slack justify-center lg:justify-between">
                    <img loading="lazy"  decoding="async" src="../assets/Images/icons/slack.svg" />
                    <span
                        >{{ $t("Join Slack") }}
                        <img loading="lazy"  decoding="async" src="../assets/Images/arrow_to_right.svg" />
                    </span>
                </div>
            </div> -->
        </div>
        <!-- <div id="contact-us" ref="contact" class="contact-us">
            <div class="left-side" id="contact">
                <span>{{ $t("Contact us") }}</span>
                <h1>
                    {{ $t("Any questions?") }}<br />
                    {{ $t("Write or call us. We will write back within 12h") }}
                </h1>
                <p>{{ $t("contactText") }}</p>
                <div class="contact-container">
                    <div class="number-container" @click="copyNumber">
                        <font-awesome-icon class="contact-icon" icon="fa-solid fa-phone" />
                        +1 (413) 772 9811
                    </div>
                    <div class="email-container">
                        <font-awesome-icon class="contact-icon" icon="fa-regular fa-envelope" />
                        <a href="mailto:contact@go-platform.dz">contact@go-platform.dz</a>
                    </div>
                </div>
            </div>
            <div class="right-side">
                <input v-model="name" type="text" :placeholder="$t('Your Name')" />
                <input v-model="email" type="email" :placeholder="$t('Your Email')" />
                <div class="select-container">
                    <select v-model="title" name="dropdown">
                        <option value="" selected disabled>{{ $t("Select a title") }}</option>
                        <option value="partnership">{{ $t("Partnership") }}</option>
                        <option value="request">{{ $t("Request") }}</option>
                        <option value="urgency">{{ $t("Urgency") }}</option>
                        <option value="feedback">{{ $t("Feedback") }}</option>
                        <option value="bug">{{ $t("Report a bug") }}</option>
                        <option value="other">{{ $t("Others") }}</option>
                    </select>
                </div>
                <textarea v-model="message" style="resize: none" rows="5" id="messageTextarea" name="message" placeholder="Message"></textarea>
                <button class="btn-contact" @click="sendContactUs" v-if="!loading" type="submit">
                    {{ $t("Send") }}
                    <font-awesome-icon class="arrow-right" icon="fa-solid fa-arrow-right" />
                </button>
                <button class="btn-contact btn-loading" v-else>
                    <LoaderComponent />
                </button>
            </div>
        </div> -->
    </div>
</template>

<script>
// import JobCarousel from "../components/Carousel.vue";
import { Vue3Marquee } from "vue3-marquee";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
// import LoaderComponent from "@/components/LoaderComponent.vue";
import ToastNotification from "@/components/ToastNotification.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: "LandingPage",
    components: {
        Vue3Marquee,
        // FontAwesomeIcon,
        // LoaderComponent,
        ToastNotification,
        // JobCarousel,
    },
    data() {
        return {
            numberCopied: false,
            email: "",
            name: "",
            title: "",
            message: "",
            loading: false,
            steps: [
                {
                    imgpath: require("../assets/landing-page-logos/Faster-logo.svg"), // Use `require` to load the image
                    icon: "",
                    title: "Slash Time-to-Hire",
                    text: "Clients see <strong>54%</strong> faster time-to-hire with our AI assessments versus traditional methods.",
                    showText: false,
                },
                {
                    imgpath: require("../assets/landing-page-logos/Save-money.svg"),
                    icon: "",
                    title: "Drive Down Cost",
                    text: "Reduce average cost-per-hire by $4,600 per candidate, saving you 23% or more.",
                    showText: false,
                },
                {
                    imgpath: require("../assets/landing-page-logos/objective-with-no-bias.svg"),
                    icon: "",
                    title: "Make Objective Decisions",
                    text: "Mitigate unconscious bias in evaluations by 63%, promoting diversity and fairness.",
                    showText: false,
                },
                {
                    imgpath: require("../assets/landing-page-logos/Attract-super-stars.svg"),
                    icon: "",
                    title: "Attract Superstars",
                    text: "Our assessments convert 72% more high-potential applicants compared to in-person evaluations.",
                    showText: false,
                },
                {
                    imgpath: require("../assets/landing-page-logos/predict-success.svg"),
                    icon: "",
                    title: "Predict Success",
                    text: "Our AI precisely forecasts candidate retention risk, improving quality of hire by 44%.",
                    showText: false,
                },
                {
                    imgpath: require("../assets/landing-page-logos/big-picture.svg"), // Make sure to use `require` for this image as well
                    icon: "",
                    title: "See Your Big Picture",
                    text: "Spot talent pipeline gaps across locations and roles with 77% more clarity and insights.",
                    showText: false,
                },
            ],
        };
    },
    methods: {
        getImg(imgFileId) {
            var image = `../assets/landing-page-logos/${imgFileId}`;
            return image;
        },
        sendContactUs() {
            this.loading = true;
            let contactForm = {
                email: this.email,
                name: this.name,
                title: this.title,
                message: this.message,
            };
            axios
                .post("https://server.go-platform.com/contact-us", contactForm, {
                    withCredentials: true,
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                    this.email = "";
                    this.name = "";
                    this.title = "Report a bug";
                    this.message = "";
                });
        },
        copyNumber() {
            navigator.clipboard.writeText("+1 (413) 772 981").catch((err) => console.error("Couldn't copy, error occured", err));
            this.numberCopied = true;
            setTimeout(() => {
                this.numberCopied = false;
            }, 5000);
        },
    },
    mounted() {
        this.$gtag.event("landingPage", {
            event_category: "visit",
            event_label: "test",
            value: 1,
        });
        let lastX, lastY;
        const heroMousemove = (e) => {
            const coordX = e.clientX;
            const coordY = e.clientY;
            if (lastX !== undefined && lastY !== undefined) {
                gsap.to(".tag", {
                    x: coordX - lastX > 0 ? -80 : 80,
                    y: coordY - lastY > 0 ? -80 : 80,
                    duration: 0.5,
                    ease: "power1.inOut",
                    stagger: 0.02,
                    smoothOrigin: true,
                });
            }
            lastX = coordX;
            lastY = coordY;
        };
        this.$refs.hero.addEventListener("mousemove", heroMousemove);
        gsap.set(".tag", { scale: 1 });
        gsap.utils.toArray(".tag").forEach((tag) => {
            tag.addEventListener("mouseover", () => {
                gsap.to(tag, { scale: 1.2, duration: 0.5, x: 0, y: 0 });
            });
            tag.addEventListener("mouseout", () => {
                gsap.to(tag, { scale: 1, duration: 0.5 });
            });
        });
        gsap.from(this.$refs.hero, {
            duration: 1.5,
            opacity: 0,
            y: 30,
            scale: 5,
            scrollTrigger: {
                start: "top center",
                trigger: this.$refs.hero,
            },
        });
        gsap.from(".accuracy_text", {
            // delay:0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,
            x: -2000,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.accuracy,
                start: "top 80%",
                toggleActions: "play reverse play reset",
                // markers:{
                //     startColor:"purple",
                //     endColor: "green", fontSize: "18px", fontWeight: "bold", indent: 20
                // }
                // scrub: 1
            },
        });
        gsap.from(".accuracy_image", {
            // delay:0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,
            x: 2000,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.accuracy,
                start: "top 80%",
                toggleActions: "play reverse play reset",
                // markers: true,
            },
        });
        gsap.from(".cost_text", {
            delay: 0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,
            x: 2000,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.time,
                start: "top 80%",
                end: "bottom 50%",
                toggleActions: "play reverse play reset",
                // markers: true,
                // scrub: 1
            },
        });
        gsap.from(".cost_image", {
            delay: 0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,

            x: -2000,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.time,
                start: "top 80%",
                end: "bottom 50%",
                toggleActions: "play reverse play reset",
                // markers: true,
            },
        });
        gsap.from(".cost_image_bg", {
            delay: 0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,
            scale: 0,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.time,
                start: "top 80%",
                end: "bottom 50%",
                toggleActions: "play reverse play reset",
                // markers: true,
            },
        });
        gsap.from(".cost2_text", {
            delay: 0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,
            x: 2000,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.cost,
                start: "top 40%",
                toggleActions: "play reverse play reset",
                // markers: true,
                // scrub: 1
            },
        });
        gsap.from(".cost2_image", {
            delay: 0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,

            x: -2000,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.cost,
                start: "top 40%",
                toggleActions: "play reverse play reset",
                // markers: true,
            },
        });
        gsap.from(".cost2_image_bg", {
            delay: 0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,
            scale: 0,
            stagger: 0.2,
            scrollTrigger: {
                trigger: this.$refs.cost,
                start: "top 40%",
                toggleActions: "play reverse play reset",
                // markers: true,
            },
        });
        gsap.from(this.$refs.slack, {
            delay: 0.4,
            // ease:"bounce",
            duration: 1,
            opacity: 0,
            scale: 0,
            scrollTrigger: {
                trigger: this.$refs.slack,
                start: "top bottom",
                toggleActions: "play none none reset",
                // markers: true,
                preventOverlaps: true,
            },
        });
        gsap.from(".text_video_wrapper", {
            // delay: 0.4,
            duration: 2,
            opacity: 0,
            scale: 0,
            scrollTrigger: {
                trigger: ".text_video_wrapper",
                start: "top 90%",
                toggleActions: "play none none reset",
                preventOverlaps: true,
            },
        });
        gsap.utils.toArray(".panels").forEach((panel, i) => {
            const pinned = i < 2 ? true : false;
            ScrollTrigger.create({
                trigger: panel,
                start: "top top",
                end: pinned ? "" : "80% bottom",
                pin: true,
                pinSpacing: false,
                // scrub: 1,
                snap: true,
                preventOverlaps: "group1",
            });
        });
    },
    beforeUnmount() {
        // this.$refs.hero.removeEventListener("mousemove", heroMousemove)
    },
};
</script>

<style scoped lang="scss">
.text-shadow {
    text-shadow: 0px 0px 6px #6c757d;
}

.contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // background: #1642f8;
    padding: 7rem;
    font-family: Roboto;
    gap: 15%;
    margin-bottom: -5%;
    background: #f4faff;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 1rem 0;

        .left-side {
            width: 100%;
            padding: 1rem 2rem;

            h1 {
                font-size: 40px;
            }

            .contact-container {
                flex-direction: column-reverse;
            }
        }

        .right-side {
            width: 100%;
            padding: 1rem 2rem;
        }

        .right-side {
            input,
            textarea,
            select,
            .select-container {
                width: 100%;
            }
        }
    }

    input {
        width: 55%;
        padding: 1rem;
        border-radius: 10px;
        //  outline-color: #2196f3;
        background-color: #fff;
        outline: none;
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1);
    }

    textarea {
        width: 55%;
        padding: 1rem;
        border-radius: 10px;
        background-color: #fff;
        // outline-color: #2196f3;
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1);

        outline: none;
    }

    select {
        width: 100%;

        cursor: pointer;

        outline: none;
    }

    /*input:focus,
    textarea:focus,
    select:focus {

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
    }*/

    .arrow-right {
        width: 13px;
        margin-left: 5px;
    }
}

.btn-contact {
    width: 55%;
    height: 60px;
    padding: 1rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-contact:hover {
    opacity: 0.85;
}

.select-container {
    width: 55%;
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: start;
}

.btn-loading {
    opacity: 0.85;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-icon {
    margin-right: 5px;
}

.contact-us p {
    text-align: start;
    font-family: Roboto;
    font-style: normal;
    color: #263238;
}

.contact-us h1 {
    text-align: start;
    font-family: Roboto;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    color: #263238 !important;
}

.contact-us span {
    text-align: start;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    color: #2196f3;
}

.left-side {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 25px;
    color: #2196f3;
}

.right-side {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 550;
    color: #2196f3;
}

.landingPageContainer {
    width: 100%;
    display: grid;
    height: fit-content;
    /*    background-image: url('../assets/Images/bg_lines.svg'), url('../assets/Images/Ellipse19.svg') ;*/
    background-repeat: no-repeat;
    background-position:
        top left 40%,
        bottom center;
    max-width: 100%;
    padding-top: 3rem;
    background: #fff;

    @media (max-width: 768px) {
        max-width: 100vw;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

.landing_text_bg {
    // background: #3f5ad8;
    // background-color: hsla(0, 0%, 100%, 1);
    // background-image: radial-gradient(
    //         at 0% 100%,
    //         hsla(186, 71%, 82%, 1) 0px,
    //         transparent 50%
    //     ),
    //     radial-gradient(at 74% 10%, hsla(186, 71%, 82%, 1) 0px, transparent 50%),
    //     radial-gradient(at 73% 90%, hsla(207, 88%, 85%, 1) 0px, transparent 50%),
    //     radial-gradient(at 0% 0%, hsla(207, 88%, 85%, 1) 0px, transparent 50%);
    height: 110vh;
    width: 100%;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    overflow-x: hidden;

    // margin-bottom: -5rem;
    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        height: 80vh;
        border-bottom-left-radius: 75px;
        border-bottom-right-radius: 75px;
        // margin-bottom: -10rem;
    }

    @media (max-width: 767.98px) {
        height: 75vh;
    }
}

.landing_text_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.blur_bg {
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.9px);
    -webkit-backdrop-filter: blur(9.9px);
}

.landing_text {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // padding: 15% 10% 10% 10%;
    margin-top: -8%;
    // margin-left: 8%;
    align-items: center;
    justify-content: center;
    text-align: center;
    // height: 40%;
    // background: url("../assets/Images/world_map2.svg");

    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        // padding: 20% 5% 10% 10%;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        // padding: 40% 5% 10% 5%;
        padding: 0;
        text-align: center;
        width: 90%;
        margin: 0 5%;
        height: 100%;
        align-items: center;
        justify-content: start;
        gap: 1.5rem;
        padding-top: 6rem;
    }
}

.landing_text > :first-child {
    font-family: "DM Sans";
    font-style: normal;
    text-transform: uppercase;
    width: 100%;
    color: #263238;
    font-size: 75px;
    font-style: normal;
    font-weight: 900;
    line-height: 90px;

    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        font-size: 36px;
        width: 80%;
        line-height: 70px;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 32px;
        width: 100%;
        text-align: center;
        line-height: 45px;
    }
}

.landing_text > :nth-child(2) {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto;
    font-weight: 500;
    margin-top: 1%;

    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        font-size: 24px;
        width: 80%;
        line-height: 30px;
        text-transform: capitalize;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 18px;
        width: 100%;
        text-align: center;
        line-height: 30px;
        margin: 3% 0;
    }
}

.landing_text > :nth-child(3) {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #4d5e80;
    width: 45%;

    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        font-size: 18px;
        line-height: 30px;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 16px;
        line-height: 30px;
        width: 100%;
        text-align: center;
    }
}

.landing_text > .btn_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 2%;

    @media (max-width: 767.98px) {
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    button {
        background-color: #2196f3;
        border: 1px solid #ccd7ec;
        border-radius: 50px;
        padding: 1% 2%;
        width: fit-content;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #00bcd4;
        margin: 1%;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        .a {
            color: #fff;
            text-decoration: none;
            padding: 0.5rem;
            gap: 4px;
            justify-content: center;
            display: flex;
            flex-direction: row;
        }

        &:hover {
            transform: scale(0.99);
            box-shadow: 0 2px 10px #0000008a inset;
            // .a {
            //     gap: 8px;
            // }
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            /* Your CSS styles for tablets go here */
            // font-size: 56px;
            // line-height: 70px;
            width: fit-content;
            padding: 2% 4%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 80%;
            padding: 3% 1%;
            font-size: 16px;
        }
    }

    > :nth-child(2) {
        color: #fff;
        background-color: #2196f3;

        a {
            color: #ffffff;
        }
    }
}

.window-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.red {
    background-color: #ff5f56;
    /* Red color */
}

.yellow {
    background-color: #ffbd2e;
    /* Yellow color */
}

.green {
    background-color: #27c93f;
    /* Green color */
}

.creditCard {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto;
    font-weight: 500;
    margin-top: 1%;

    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        font-size: 24px;
        width: 80%;
        line-height: 30px;
        text-transform: capitalize;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 18px;
        width: 100%;
        text-align: center;
        line-height: 30px;
        margin: 3% 0;
    }
}

.landing_image {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 250px;
    z-index: 1;

    img {
        width: 150%;
        height: 150%;
    }
}

.content_wrapper {
    z-index: 2;
    background-color: #fff;
    // box-shadow: 0px 0px 10px 3px #e8e8e8;
    // -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    // -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    // background-image: url("../assets/background/bg.png");
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: bottom center;
    width: 100%;
    // margin: 0 5%;
    overflow-x: hidden;
    height: fit-content;
    margin-top: -12%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;

    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        border-top-left-radius: 75px;
        border-top-right-radius: 75px;
        border-bottom-left-radius: 75px;
        border-bottom-right-radius: 75px;
        width: 95%;
        margin: 0 2.5%;
        // margin-top: -15%;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        border-top-left-radius: 75px;
        border-top-right-radius: 75px;
        border-bottom-left-radius: 75px;
        border-bottom-right-radius: 75px;
        // margin-top: -25%;
        padding: 5% 0 10% 0;
        // margin-top: 4rem;
        margin-top: -5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}

.partners {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 250px;
    align-items: center;
    width: 100%;

    // background-color: #f6f7fc;
    span {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        align-items: center;
        width: 100%;
        color: #9b9e9e;
        text-transform: capitalize;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 150px;
        align-items: center;
        width: 100%;

        @media (max-width: 767.98px) {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.75rem;
            padding: 0 1rem;
        }
    }

    img {
        width: 8%;
        aspect-ratio: 3/2;
        object-fit: contain;
        mix-blend-mode: color-burn;

        @media (min-width: 768px) and (max-width: 991.98px) {
            height: 37px;
            width: 22%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            height: fit-content;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 99%;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        width: 100%;
        height: 150px;
        text-align: center;

        span {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            align-items: center;
            width: 100%;
            color: #9b9e9e;
            text-transform: capitalize;
        }
    }
}

.text_video_wrapper {
    margin: 5% auto;
    width: 70%;

    .text_video {
        height: fit-content;
        display: flex;
        flex-direction: row;

        // margin-bottom: 8%;
        @media (min-width: 768px) and (max-width: 991.98px) {
            display: flex;
            flex-direction: column;
            margin: 2%;
            height: fit-content;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            display: flex;
            flex-direction: column;
            margin: 2%;
            height: fit-content;
        }
    }

    img {
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
    }
}

.span_2_cols {
    grid-column: span 2;
    margin: 2% 0;
}

.span_2_rows {
    grid-row: 2 / span 2;
    grid-column-start: 2;
}

.text_video_wrapper > :first-child {
    // font-family: "DM Sans";
    // font-style: normal;
    // font-weight: 700;
    // font-size: 50px;
    // line-height: 65px;
    // text-transform: capitalize;
    // padding-top: 3%;
    // color: #343637;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 37px;
        border-bottom: 1px solid #f6f7fc;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 24px;
        line-height: 35px;
        margin: 5% 0;
        // padding: 5%;
        border-bottom: 1px solid #f6f7fc;
    }
}

.text {
    display: flex;
    flex-direction: column;
    margin-top: 8%;
    width: 80%;
    align-items: baseline;
    // margin-left: 5%;
    height: 100%;
    justify-content: space-evenly;
    // padding-top: 17%;

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        padding: 0;
        margin: 3% 0%;
        margin-bottom: 1%;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        padding: 1%;
        margin-top: 3%;
        display: none;
    }
}

.text > :first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 132.5%;
    text-align: left;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #343637;
    margin-bottom: 5%;

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        font-size: 32px;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 18px;
    }
}

.text > :nth-child(2) {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.02em;
    text-align: left;
    color: #4d5e80;
    width: 80%;
    margin-bottom: 5%;

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        line-height: 50px;
        font-size: 24px;
        margin-bottom: 5%;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        line-height: 30px;
        font-size: 16px;
        margin-bottom: 5%;
    }
}

.start_btn {
    background: #2196f3;
    border-radius: 50px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #f6f7fc;
    border: none;
    padding: 3% 6%;
    position: relative;

    .start_btn_shadow {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 20%;
        left: -5%;
        background: #2196f3;
        opacity: 0.1;
        border-radius: 50px;
        z-index: -1;
    }

    .shadow_2 {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 40%;
        left: -10%;
        background: #00bcd4;
        opacity: 0.0535;
        border-radius: 50px;
        z-index: -1;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 16px;
        margin-bottom: 5%;
    }
}

iframe {
    margin: 9% 0% 0% 0%;
    width: 90%;
    height: 350px;

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        width: 100%;
        height: 40vh;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        padding: 0%;
        width: 99%;
        height: fit-content;
        // margin: 2%;
    }
}

.process_qt_wrapper {
    width: 100%;
    margin: 10% 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .process_qt {
        position: relative;
        width: 50%;
        height: 250px;
        // background-color: hsla(0, 0%, 100%, 1);
        // background-image: radial-gradient(
        //     at 54% 0%,
        //     hsla(188, 96%, 52%, 1) 0px,
        //     transparent 50%
        //   ),
        //   radial-gradient(at 0% 0%, hsla(205, 100%, 56%, 1) 0px, transparent 50%),
        //   radial-gradient(at 100% 0%, hsla(145, 95%, 82%, 1) 0px, transparent 50%),
        //   radial-gradient(at 0% 100%, hsla(205, 100%, 56%, 1) 0px, transparent 50%),
        //   radial-gradient(at 55% 99%, hsla(188, 96%, 52%, 1) 0px, transparent 50%),
        //   radial-gradient(at 100% 100%, hsla(145, 95%, 82%, 1) 0px, transparent 50%);
        background-color: #2196f3;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        color: #ffffff;

        // overflow: hidden;
        // box-shadow: 9px 11px 26px -12px #5a7fff;
        // -webkit-box-shadow: 9px 11px 26px -12px #5a7fff;
        // -moz-box-shadow: 9px 11px 26px -12px #5a7fff;
        div {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: 50px;
        }

        span {
            width: 95%;
            font-size: 36px;
            font-weight: 600;
            text-transform: capitalize;
            letter-spacing: 8px;
        }

        .process_qt_shadow {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 20%;
            left: -5%;
            background: #00bcd4;
            opacity: 0.0535;
            border-radius: 50px;
            z-index: -1;
        }

        .shadow_1 {
            background: #00bcd4;
            opacity: 0.0535;
            transform: rotate(30deg);
        }

        .shadow_2 {
            background: #00bcd4;
            opacity: 0.1;
            transform: rotate(10deg);
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        /* Your CSS styles for tablets go here */
        margin-top: 15%;
        margin-bottom: 20%;

        .process_qt {
            width: 90%;

            span {
                width: 90%;
                font-size: 32px;
                letter-spacing: 8px;
                font-weight: 600;
            }
        }
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        margin-top: 20%;

        .process_qt {
            width: 100%;
            height: fit-content;

            span {
                width: 90%;
                font-size: 16px;
                letter-spacing: 5px;
                font-weight: 400;
                padding: 8%;
            }
        }
    }
}

.proccess_steps_wrapper {
    width: 100%;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7rem;

    & > :first-child {
        // font-family: "Inter";
        // font-style: normal;
        // font-weight: 600;
        // font-size: 40px;
        // line-height: 132.5%;
        text-align: center;
        // letter-spacing: 0.02em;
        // text-transform: capitalize;
        // color: #343637;
        margin: -5% auto -10% auto;
        width: 70%;
        color: var(--blue-gray-900, #0f172a);
        text-align: left;
        /* Heading/2 */
        font-family: Roboto;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        @media (min-width: 768px) and (max-width: 991.98px) {
            // flex-direction: column-reverse;
            width: 80%;
            font-size: 32px;
            margin: 0% 15% 3% 5%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 80%;
            font-size: 18px;
            margin: 5% 15% 3% 5%;
        }
    }

    & > :nth-child(2) {
        color: var(--blue-gray-900, #0f172a);
        text-align: center;
        /* Body/XL */
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
        padding: 0% 15%;
        margin-bottom: 5%;
    }

    .process_steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 0;
        overflow: hidden;
        // background: #fff;

        @media (max-width: 767.98px) {
            flex-direction: column;
            padding: 2rem 0;
        }

        & > :first-child {
            width: 80%;
            // border: 2px solid red;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            padding: 2.5rem;
            // margin-top: -2%;

            span {
                color: #3789ff;

                /* Caption/M */
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 20px */
                letter-spacing: 3px;
                text-transform: uppercase;
            }

            h1 {
                color: #343637;

                /* Heading/2 */
                font-family: Roboto;
                font-size: 48px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%;
            }

            p {
                color: #343637;

                /* Body/L */
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                text-align: left;
            }
        }

        & > :nth-child(2) {
            position: relative;
            width: 100%;

            // height: 656px;
            img {
                // width: 100%;
                // height: fit-content;
            }

            // background-image: url("../assets/Images/Landing_page.png");
            // background-color: #f6f7fc;
        }
    }

    & > :nth-child(4) {
        @media (min-width: 768px) {
            flex-direction: row-reverse;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        font-size: 32px;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 18px;
    }
}

.grad {
    background: #3789ff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;

    .arch {
        position: absolute;
        top: 50%;
        right: 0;
        height: 100%;
        width: 100%;
    }

    /*text-fill-color: transparent;*/
}

// .bottom_text {
//     height: fit-content;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     align-items: center;
//     margin: 10% 0% 5% 0%;
//     justify-items: center;
//     // gap: 20%;
//     @media (min-width: 768px) and (max-width: 991.98px) {
//         // flex-direction: column-reverse;
//         padding: 0;
//         height: 60%;
//         flex-direction: column;
//         font-size: 24px;
//         line-height: 30px;
//     }
//     @media (max-width: 767.98px) {
//         /* Your CSS styles for smartphones go here */
//         padding: 0;
//         margin-top: 30%;
//         margin-bottom: 5%;
//         display: flex;
//         flex-direction: column;
//         font-size: 24px;
//         line-height: 30px;
//     }
// }
// .bottom_text > :first-child {
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 54px;
//     line-height: 94px;
//     text-align: center;
//     text-transform: capitalize;
//     color: #343637;
//     width: 75%;
//     margin: 0 0 3% 0;
//     @media (min-width: 768px) and (max-width: 991.98px) {
//         font-size: 37px;
//         line-height: 75px;
//         margin: 3% 0;
//         border-bottom: 1px solid #f6f7fc;
//     }
//     @media (max-width: 767.98px) {
//         /* Your CSS styles for smartphones go here */
//         font-size: 24px;
//         line-height: 50px;
//         border-bottom: 1px solid #f6f7fc;
//     }
// }
// .bottom_text > :nth-child(2) {
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 600;
//     font-size: 30.0021px;
//     line-height: 36px;
//     letter-spacing: 0.02em;
//     color: #4d5e80;
//     // margin: 3% 0;
//     @media (min-width: 768px) and (max-width: 991.98px) {
//         // font-size: 37px;
//         margin: 3% 0;
//     }
//     @media (max-width: 767.98px) {
//         /* Your CSS styles for smartphones go here */
//         font-size: 24px;
//         margin: 12% 0;
//         boder: 2px solid black;
//     }
// }
// .bottom_text > :nth-child(4) {
//     width: 100%;
//     // margin-top: 5%;
//     // display: flex;
//     // justify-content: center;
//     // align-items: center;
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 21.0015px;
//     line-height: 25px;
//     color: #7a8994;
//     // background-color: #f6f7fc;
//     margin: 5% 0;
//     @media (min-width: 768px) and (max-width: 991.98px) {
//         /* Your CSS styles for tablets go here */
//         // height: 150px;
//         margin-top: 10%;
//     }
//     @media (max-width: 767.98px) {
//         /* Your CSS styles for smartphones go here */
//         margin-top: 15%;
//     }
// }

.bottom_text {
    width: 80%;
    margin: 15% 10%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    border-radius: 30px;
    border: 2px solid #ccd7ec;
    background: #fff;

    padding-top: 0.25rem;

    @media (max-width: 768px) {
        margin: 15% auto;
        width: 90%;
    }

    .slack {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            width: 56.25px;
            height: 56.25px;
        }

        width: fit-content;
        gap: 1rem;
        margin: 5% auto;

        & > :nth-child(2) {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    span {
        color: #3789ff;
        margin-top: 3%;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 300%;
        /* 60px */
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    h1 {
        color: var(--blue-gray-900, #0f172a);
        // width: 60%;
        // margin: 3% 20%;
        text-align: center;
        font-family: Roboto;
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        line-height: 115%;
        margin: 2%;

        @media (max-width: 768px) {
            font-size: 32px;
        }
    }

    p {
        width: 80%;
        margin: 0 10%;
        color: var(--blue-gray-900, #0f172a);
        text-align: center;
        /* Body/XL */
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
        margin-bottom: 3%;
    }

    img {
        width: 98%;
        margin: auto;
        margin-top: 0;

        @media (max-width: 768px) {
            width: 92%;
        }
    }
}

.signup_btn {
    display: flex;
    gap: 40px;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 21.0015px;
    line-height: 25px;
    // width: 40%;
    color: #7a8994;

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        flex-direction: column;
        gap: 20px;
        // margin-bottom: 8%;
    }
}

.signup_btn > button {
    background-color: #fff;
    border: none;
    border-radius: 50px;
    padding: 3% 2rem;
    width: 240ox;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #00bcd4;
    margin: 1%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    height: 68px;

    .a {
        color: #2196f3;
        text-decoration: none;
    }

    &:hover {
        transform: scale(0.99);
        box-shadow: 0 2px 10px #0000008a inset;
    }
}

.signup_btn > :first-child {
    background-color: #f6f7fc;
}

.signup_btn > :nth-child(2) {
    background-color: #2196f3;
    color: #fff;
}
</style>
