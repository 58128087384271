<template>
    <div class="chart-container">
        <span class="chart-title">Investements</span>
        <span class="chart-legend"><span style="border-color: #fab2ff"></span> Past</span>
        <span class="chart-legend"><span style="border-color: #3361ff"></span> Future</span>
        <select name="chart-period" id="period">
            <option value="weekly">Last 7 days</option>
            <option value="monthly">Last month</option>
            <option value="yearly">Last year</option>
        </select>
        <svg class="chart-svg"></svg>
    </div>
</template>
<script>
import * as d3 from "d3";
export default {
    name: "LineChart",
    data() {
        return {
            data: [
                { date: "24-Apr-07", amount: 93.24 },
                { date: "25-Apr-07", amount: 95.35 },
                { date: "26-Apr-07", amount: 98.84 },
                { date: "27-Apr-07", amount: 99.92 },
                { date: "30-Apr-07", amount: 99.8 },
                { date: "1-May-07", amount: 99.47 },
                { date: "2-May-07", amount: 100.39 },
                { date: "3-May-07", amount: 100.4 },
                { date: "4-May-07", amount: 100.81 },
                { date: "7-May-07", amount: 103.92 },
                { date: "8-May-07", amount: 105.06 },
                { date: "9-May-07", amount: 106.88 },
                { date: "10-May-07", amount: 107.34 },
            ],
            data2: [
                { date: "24-Apr-07", amount: 110.34 },
                { date: "25-Apr-07", amount: 108.88 },
                { date: "26-Apr-07", amount: 104.06 },
                { date: "27-Apr-07", amount: 103.92 },
                { date: "30-Apr-07", amount: 101.81 },
                { date: "1-May-07", amount: 100.4 },
                { date: "2-May-07", amount: 98.39 },
                { date: "3-May-07", amount: 95.47 },
                { date: "4-May-07", amount: 93.82 },
                { date: "7-May-07", amount: 91.92 },
                { date: "8-May-07", amount: 90.84 },
                { date: "9-May-07", amount: 88.35 },
                { date: "10-May-07", amount: 87.24 },
            ],
        };
    },
    mounted() {
        const containerWidth = document.querySelector(".chart-container").clientWidth;
        const containerHeight = document.querySelector(".chart-container").clientHeight;

        const margin = { top: 20, right: 0, bottom: 40, left: 50 };
        const width = containerWidth - margin.left - margin.right;
        const height = containerHeight - margin.top - margin.bottom;
        //const paddingForText = 5;

        const svg = d3.select("svg").attr("width", width).attr("height", height);
        const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        const parseTime = d3.timeParse("%d-%b-%y");

        // Create X and Y scales
        const yExtent = d3.extent([...this.data, ...this.data2], function (d) {
            return d.amount;
        });

        const x = d3
            .scaleTime()
            .domain(
                d3.extent(this.data, function (d) {
                    return parseTime(d.date);
                }),
            )
            .range([0, width - margin.left - margin.right]);

        const y = d3
            .scaleLinear()
            .domain(yExtent)
            .range([height - margin.top - margin.bottom, 0])
            .nice();

        //const hist =d3.histogram().x((d)=>{return x(parseTime(d.date))});
        // Create a line generator
        const line = d3
            .line()
            .x(function (d) {
                return x(parseTime(d.date));
            })
            .y(function (d) {
                return y(d.amount);
            });

        // Append X and Y axes to the chart
        g.append("g")
            .attr("transform", "translate(0," + (height - margin.top - margin.bottom) + ")")
            .call(d3.axisBottom(x).ticks(5).tickFormat(d3.timeFormat("%b %d")).tickSizeOuter(0))
            .selectAll("text")
            .attr("fill", "black");

        g.append("g")
            .call(d3.axisLeft(y).ticks(5))
            .append("text")
            .attr("fill", "black")
            .attr("transform", "rotate(-90)")
            .attr("y", -margin.left)
            .attr("dy", "1.5rem")
            .attr("text-anchor", "end")
            .text("Price ($)");

        // Add grid lines
        // Add grid lines
        /*g.append("g")
  .attr("class", "grid")
  .call(d3.axisLeft(x).ticks(5).tickSize(-width + margin.left + margin.right).tickFormat(""))
  .selectAll("line")
  .attr("stroke", "#FFF4FE");*/

        // Append the first data line
        g.append("path").datum(this.data).attr("fill", "none").attr("stroke", "#2196f3").attr("stroke-width", 1.5).attr("d", line);

        // Append the second data line
        g.append("path").datum(this.data2).attr("fill", "none").attr("stroke", "#fab2ff").attr("stroke-width", 1.5).attr("d", line);

        // Add X-axis label
        g.append("text")
            .attr("class", "x-axis-label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", height - margin.bottom + 20)
            .attr("fill", "black")
            .text("Date");

        // Add labels to data points
        /*g.append("g")
    .selectAll("text")
    .data(this.data)
    .enter()
    .append("text")
    .attr("x", function (d) {
      return x(parseTime(d.date)) - paddingForText;
    })
    .attr("y", function (d) {
      return y(d.amount) + paddingForText;
    })
    .attr("fill", "red")
    .text(function (d) {
      return d.amount;
    });*/
        const tooltip = d3.select("body").append("div").attr("class", "tooltip").style("opacity", 0);

        g.selectAll(".data-point")
            .data(this.data)
            .enter()
            .append("circle")
            .attr("class", "data-point")
            .attr("cx", function (d) {
                return x(parseTime(d.date));
            })
            .attr("cy", function (d) {
                return y(d.amount);
            })
            .attr("r", 4)
            .on("mouseover", function (d) {
                //const [mouseX, mouseY] = d3.pointer(event);
                //const mouseX = d3.event.pageX || d3.event.clientX;
                //const mouseY = d3.event.pageY || d3.event.clientY;
                tooltip.transition().duration(200).style("opacity", 0.9);
                tooltip
                    .html("Date: " + d.date + "<br/>Amount: " + d.amount)
                    .style("left", d3.event.pageX + 25 + "px")
                    .style("top", d3.event.pageY + "px");
            })
            .on("mouseout", function () {
                tooltip.transition().duration(500).style("opacity", 0);
            });
    },
};
</script>

<style lang="scss" scoped>
/*.chart-container {
  background: #fff;
  height: 100%;
  width: 60%;
}*/
.chart-container {
    display: flex;
    flex-flow: row wrap;
    grid-column: span 2;
    justify-content: space-between;
    align-items: baseline;
    .chart-title {
        font-weight: 600;
        font-size: 18.5602px;
        line-height: 23px;
        flex: 1 0 25%;
    }
    .chart-legend {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        flex: 1 0 25%;

        span {
            border-radius: 50%;
            border: 5px solid black;
            position: relative;
            top: 39%;
            width: 0px;
            height: 0px;
        }
    }
    select {
        flex: 1 0 25%;
    }
    .chart-svg {
        flex: 2 0 100%;
    }
}

.x-axis-label,
.y-axis-label {
    font-size: 12px;
}
</style>
